import React from 'react'
import PropTypes from 'prop-types'
import styled from '@xstyled/styled-components'
// import { Location } from '@reach/router'

import IconArrowLeft from '../svgs/icons/icon-arrow-left.svg'

const StyledNav = styled.nav`

`
const StyledButton = styled.button`
  display: inline-block;
  font-size: 16rpx;
  line-height: 19rpx;
  color: var(--ssy-black);
  font-weight: 800;
  text-decoration: none;
  margin: 0 0 45rpx;
  padding: 0;
  border: 0;
  /* transition: color 0.5s ease-in-out; */

  .inner-wrapper {
    display: inline-block;
    border-bottom: 2px solid var(--ssy-orange);
  }

  .icon-arrow-left {
    position: relative;
    width: 14rpx;
    top: 2rpx;
    right: 8rpx;

    g {
      fill: var(--ssy-black);
      /* transition: fill 0.5s ease-in-out; */
    }
  }

  &:hover {
    cursor: pointer;
    color: var(--ssy-black);

    .inner-wrapper {
      border-color: transparent;
    }

    .icon-arrow-left {
      g {
        fill: var(--ssy-black);
      }
    }
  }
`

const NavBack = ({ linkText }) => {
  return (
    <StyledNav>
      <StyledButton aria-label="navigate back" onClick={() => { window.history.back() }}>
        <IconArrowLeft />
        <div className="inner-wrapper">{linkText}</div>
      </StyledButton>
    </StyledNav>
  )
}

export default NavBack

NavBack.defaultProps = {
  linkText: 'Back'
}

NavBack.propTypes = {
  linkText: PropTypes.string,
}
