import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled, { Box, css, up } from '@xstyled/styled-components'
import _ from 'underscore'

import Container from '../components/layout/container'
import Layout from '../components/layout/layout'
import NewsEventsCard from '../components/news-events-card'
import NavBack from '../components/nav-back'
import SEO from '../components/seo'

const NewsEventsGrid = styled.div`
  width: 100%;
  display: grid;
  padding: 60rpx 0;
  grid-template-columns: 1fr;
  grid-column-gap: 55rpx;
  grid-row-gap: 55rpx;

  ${up(
  'md',
  css`
      grid-template-columns: 1fr 1fr;
    `
)}

${up(
  'lg',
  css`
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `
)}
`
const NewsEventsFilter = styled.div`
  display: flex;
  align-items: center;
`

const FilterTitleMobile = styled.div`
  font-size: 16rpx;
  line-height: 19rpx;
  font-weight: 800;
  padding-right: 15rpx;
  display: block;
  margin-bottom: 20rpx;

  ${up(
  'sm',
  css`
      display: none;
    `
)}
`

const FilterTitle = styled.div`
  font-size: 16rpx;
  line-height: 19rpx;
  font-weight: 800;
  padding-right: 15rpx;
  display: none;

  ${up(
  'sm',
  css`
      display: block;
    `
)}
`

const FilterButton = styled.button`
  padding: 16rpx 26rpx;
  margin-right: 10rpx;
  font-size: 16rpx;
  line-height: 19rpx;
  font-weight: 800;
  color: var(--ssy-orange);
  background-color: var(--ssy-gray);
  border: 0;
  /* transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out; */

  &:last-child {
    margin-right: 0;
  }

  &.active {
    color: var(--ssy-white);
    background-color: var(--ssy-black);
  }

  &:hover {
    cursor: pointer;
    color: var(--ssy-white);
    background-color: var(--ssy-black);
  }
`

const NewsIndexFunc = ({ data, pageContext }) => {
  const [filter, setFilter] = useState('all')
  let newsEvents = { data: data.drupal.newsEventsData.entities }
  let filteredData = []

  const filterNewsType = (key) => {
    filteredData = newsEvents.data.filter(i => {
      return [key].includes(i.fieldType)
    })
  }

  if (filter === 'all') {
    filteredData = newsEvents.data
  }

  if (filter === 'news') {
    filterNewsType('news')
  }

  if (filter === 'events') {
    filterNewsType('event')
  }

  const lowerLang = pageContext.lang.toLowerCase()
  let capLang = lowerLang.charAt(0).toUpperCase() + lowerLang.charAt(1)
  capLang = capLang === 'Zh' ? 'ZhHant' : capLang
  const translatedData = data[`all${capLang}Json`].edges[0].node

  return (
    <Layout lang={pageContext}>
      <SEO title={translatedData.newsEvents.indexTitle} />
      <Container>
        <Box row justifyContent="center">
          <Box col={{ lg:  2 / 3}}>
            <NavBack linkText={translatedData.cta.back} />
            <h1>{translatedData.newsEvents.indexTitle}</h1>
            <FilterTitleMobile>{translatedData.newsEvents.filter.showing}</FilterTitleMobile>
            <NewsEventsFilter>
              <FilterTitle>{translatedData.newsEvents.filter.showing}</FilterTitle>
              <FilterButton type="button" aria-label="display all" className={`${filter ===`all` ? 'active' : ``}`} onClick={() => { setFilter(`all`) }}>{translatedData.newsEvents.filter.all}</FilterButton>
              <FilterButton type="button" aria-label="display news" className={`${filter ===`news` ? 'active' : ``}`} onClick={() => { setFilter(`news`) }}>{translatedData.newsEvents.filter.news}</FilterButton>
              <FilterButton type="button" aria-label="display events" className={`${filter ===`events` ? 'active' : ``}`} onClick={() => { setFilter(`events`) }}>{translatedData.newsEvents.filter.events}</FilterButton>
            </NewsEventsFilter>
          </Box>
        </Box>
        <NewsEventsGrid>
          {filteredData.map((element, index) => (
             <NewsEventsCard
              cta={element.fieldExternalLink && element.fieldExternalLink.title}
              date={element.fieldDate.value}
              image={element.fieldCoverImage && element.fieldCoverImage.url}
              linkExt={element.fieldExternalLink && element.fieldExternalLink.url.path}
              slug={element.fieldSlug}
              title={element.entityLabel}
              type={element.fieldType}
            />
          ))}
        </NewsEventsGrid>
      </Container>
    </Layout>
  )
}

export default NewsIndexFunc

export const query = graphql`
  query allNewsIndexQuery {
    drupal {
      newsEventsData: nodeQuery(limit: 100, offset: 0,
        filter: {
          conditions: [{
            operator: EQUAL, field: "type", value: ["news_event"]
          }]
        }
        sort: [{
          field: "field_date",
          direction: DESC
        }]
      ) {
        entities {
          entityId,
          entityLabel,
          ... on Drupal_NodeNewsEvent {
            fieldType,
            fieldSlug,
            fieldDate {
              value
            },
            fieldExternalLink {
              title,
              url {
                path
              }
            },
            fieldCoverImage {
              url
            }
          }
        }
      }
    }
    allNewsJson(limit: 4, filter: {}, sort: {fields: publish_date, order: DESC}) {
      edges {
        node {
          slug
          title
          type
          wysiwyg
          id
          publish_date(fromNow: true)
        }
      }
    }
    allEnJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allZhHantJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allKoJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allArJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allBnJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allEsJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
  }
`
